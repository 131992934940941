<template>
    <div class="d-flex flex-wrap flex-stack my-5">
        <router-link to="/module" class="btn btn-primary align-self-center">
            <span class="svg-icon svg-icon-2">
                <inline-svg src="/media/icons/duotune/arrows/arr021.svg"/>
            </span>
            {{ $t("menu.modules") }}
        </router-link>
        <div class="float-end" v-if="table.data.length">
            <el-tooltip :content="$t('common.exportTooltip')" popper-class="max-w-300px" placement="bottom">
                <button class="btn btn-danger align-self-center ms-3" :data-kt-indicator="exportLoading ? 'on' : null" :disabled="exportLoading" v-on:click="exportRequest">
                    <span v-if="!exportLoading" class="indicator-label">
                        <span class="svg-icon svg-icon-1">
                            <inline-svg src="/media/icons/duotune/files/fil021.svg"/>
                        </span>
                        {{ $t("common.export") }}
                    </span>

                    <span v-if="exportLoading" class="indicator-progress">
                        <span class="spinner-border spinner-border-sm align-middle me-2"></span>
                        {{ $t("messages.wait") }}
                    </span>
                </button>
            </el-tooltip>
        </div>
    </div>

    <custom-table
        :title="$t('pages.module.yksLgsHazirlikDestegi.title')"
        :subTitle="$t('pages.module.yksLgsHazirlikDestegi.subTitle')"
        selectableRows
        rowKey="id"
        :items="table.data"
        :loading="table.loading"
        :columns="fields"
        :actions="actions"
        :pagination="table.pagination"
        filterComponentName="ModuleYksLgsHazirlikDestegiFilter"
        @action="handleClickAction"
        @selectableRows="handleSelectedRow"
        @changeTable="handleTableChange">

        <template v-slot:phone="{ row: record }">
            <a :href="'tel:' + record.mobile_phone" class="text-gray-600 text-hover-primary mb-1">
                {{ record.mobile_phone }}
            </a>
        </template>

        <template v-slot:createdAt="{ row: record }">
            {{ $moment(record.created_at).format("DD.MM.YYYY - HH:mm:ss") }}
        </template>

        <template v-slot:actions="{ row: record }">
            <div class="d-flex justify-content-end">
                <el-popconfirm :title="$t('messages.sureDelete')" :confirm-button-text="$t('btn.yes')" :cancel-button-text="$t('btn.no')" @confirm="deleteRecord([record.id])">
                    <template #reference>
                        <a class="btn btn-icon btn-bg-light btn-active-color-primary btn-sm">
                            <span class="svg-icon svg-icon-3">
                                <inline-svg src="/media/icons/duotune/general/gen027.svg"/>
                            </span>
                        </a>
                    </template>
                </el-popconfirm>
            </div>
        </template>
    </custom-table>
</template>

<script>
import CustomTable from "@/components/custom-table";

export default {
    name: "index",
    components: {
        CustomTable
    },
    data() {
        return {
            fields: [
                {
                    name: this.$t("common.id"),
                    key: "id",
                    class: ""
                },
                {
                    name: this.$t("pages.module.yksLgsHazirlikDestegi.cols.name_surname"),
                    key: "name_surname",
                },
                {
                    name: this.$t("pages.module.yksLgsHazirlikDestegi.cols.requested_training"),
                    key: "requested_training",
                },
                {
                    name: this.$t("pages.module.yksLgsHazirlikDestegi.cols.phone"),
                    scopedSlots: {customRender: "phone"}
                },
                {
                    name: this.$t("pages.module.yksLgsHazirlikDestegi.cols.createdAt"),
                    scopedSlots: {customRender: "createdAt"}
                },
                {
                    name: this.$t("common.action"),
                    key: "action",
                    scopedSlots: {customRender: "actions"}
                }
            ],
            actions: [
                {
                    name: "refresh",
                    icon: "bi-arrow-repeat",
                    label: "btn.refresh"
                },
                {
                    name: "delete",
                    icon: "bi-trash",
                    label: "btn.delete"
                }
            ],
            selectedRowKeys: [],
            form: {
                title: '',
                data: {}
            },
            exportLoading: false,
            filterParameters: {},
        }
    },
    computed: {
        table() {
            return this.$store.state.module.yksLgsHazirlikDestegi.table;
        }
    },
    mounted() {
        this.setCurrentPageBreadcrumbs(this.$t("pages.module.yksLgsHazirlikDestegi.title"), [this.$t("menu.moduleManagement")]);

        this.filterParameters = Object.assign(this.$root.getFilterWithUrl({}), {
            sort: 'created_at:desc'
        });

        this.$store.dispatch('module/yksLgsHazirlikDestegi/get', {
            page: {},
            filterData: this.filterParameters
        });
    },
    methods: {
        deleteRecord(id) {
            this.$store.dispatch("module/yksLgsHazirlikDestegi/delete", {
                id: id
            }).then((successDeleted) => {
                this.selectedRowKeys = this.selectedRowKeys.filter(x => !successDeleted.includes(x));
            });
        },
        handleClickAction(name) {
            switch (name) {
                case "refresh":
                    this.refreshTable();
                    break;

                case "delete":
                    this.deleteRecord(this.selectedRowKeys.flat());
                    break;

                default:
                    break;
            }
        },
        refreshTable() {
            this.$store.dispatch("module/yksLgsHazirlikDestegi/refresh");
        },
        handleTableChange(pagination, filterData) {
            this.filterParameters = Object.assign({
                sort: 'created_at:desc',
            }, filterData);

            this.$store.dispatch("module/yksLgsHazirlikDestegi/get", {
                page: pagination,
                filterData: this.filterParameters
            });
        },
        handleSelectedRow(record) {
            this.selectedRowKeys = record;
        },
        exportRequest() {
            this.exportLoading = true;
            this.axios({
                url: this.endpoints['module_yks_lgs_hazirlik_destegi_export'],
                method: 'get',
                responseType: "blob",
                params: this.filterParameters
            }).then(response => {
                const type = response.headers['content-type']
                const blob = new Blob([response.data], {type: type, encoding: 'UTF-8'})
                const link = document.createElement('a')
                link.href = window.URL.createObjectURL(blob)
                link.click();
            }).finally(() => {
                this.exportLoading = false;
            });
        }
    }
}
</script>

<style scoped>

</style>